import swal, { type SweetAlertOptions } from "sweetalert2";
import { type FetchContext } from "ofetch";
import { Env } from "~/parts/core";
import { getTokens, isTokenExpired, refreshTokenAsync, signOut } from "~/parts/auth";
import { type NitroFetchOptions, type NitroFetchRequest, type TypedInternalResponse } from "nitropack"

export declare interface EApi {
    get<ResT>(url: string, fetchOptions?: NitroFetchOptions<string>):
        Promise<TypedInternalResponse<NitroFetchRequest, ResT, "get">>;
    post<ResT, DataT = null>(url: string, data?: DataT | null, fetchOptions?: NitroFetchOptions<NitroFetchRequest>):
        Promise<TypedInternalResponse<NitroFetchRequest, ResT, "post">>;
    put<ResT, DataT = null>(url: string, data?: DataT | null, fetchOptions?: NitroFetchOptions<NitroFetchRequest>):
        Promise<TypedInternalResponse<NitroFetchRequest, ResT, "put">>;
    remove<ResT, DataT>(url: string, data?: DataT, fetchOptions?: NitroFetchOptions<NitroFetchRequest>):
        Promise<TypedInternalResponse<NitroFetchRequest, ResT, "delete">>;
    postWithProgress<ResT, DataT extends FormData = FormData>(url: string, data: DataT,
        uploadProgress: (e: ProgressEvent) => void):
        Promise<ResT>;
}

export const eApi = (useApiKey: boolean = false) => {

    const abcs: { [key: string]: AbortController } = {};

    async function onRequest<EType>(ctx: FetchContext<EType, "json">): Promise<void> {
        const config = useRuntimeConfig();
        ctx.options.baseURL = config.public.API_URL;
        const hasContentType = !!ctx.options.headers && (ctx.options.headers as Record<string, string>)["Content-Type"];
        const headers: { [key: string]: any } = !hasContentType ? {
            "Content-Type": "application/json"
        } : {};
        let authToken = getTokens();
        if (useApiKey) {
            ctx.options.baseURL = "/remote/proxy/apiKey";
        }
        if (authToken.value?.token) {
            const authProvider = config.public.auth.provider as any;
            const refreshTokenPath = `${config.public.auth.baseURL}${authProvider.endpoints.refresh.path}`;
            const signInPath = `${config.public.auth.baseURL}${authProvider.endpoints.signIn.path}`;
            if (isTokenExpired().value && ctx.request != refreshTokenPath
                && ctx.request != signInPath) {
                await refreshTokenAsync();
                if (!authToken.value) {
                    abcs[ctx.request as string]?.abort("Get away");
                    const { signOut } = useEAuth();
                    signOut({ redirect: false });
                }
            }
            headers['Authorization'] = `Bearer ${authToken.value.token}`;
        }
        ctx.options.headers = headers;
    }

    async function onResponseError<EType extends Record<string, any>>(context: FetchContext<EType>): Promise<void> {
        const config = useRuntimeConfig();
        const env = config.public.env;
        useLoading().hide(true);
        const message = env == Env.Developement
            ? (context.response?._data ? context.response?._data["message"]
                ?? context.response._data["errors"]
                ?? context.response._data : context.response?.statusText)
            : context.response?.statusText;
        const result = await Swal.fire({
            title: "Error",
            text: JSON.stringify(message),
            icon: 'error'
        } as SweetAlertOptions);
        if (result.isConfirmed) {
            const getLocalePath = useLocalePath();
            const homeRoute = getLocalePath({ name: "app" });

            const request = (context.request as string).replace(config.public.API_URL, "").replace(/^\//, "");
            abcs[request]?.abort(message);

            if (context.response?.status == 410) {
                const localepath = getLocalePath({ name: "app-auth-login", query: { redirect: useRoute().path } });
                useRouter().push(localepath);
            } else if (context.response?.status == 401) {
                useRouter().push(homeRoute);
            }
            return;
        }

    }

    function doRequest<ResT, DataT = null>(url: string, data: DataT | null = null,
        fetchOptions: NitroFetchOptions<NitroFetchRequest>)
    {
        const abc = new AbortController();
        abcs[url] = abc;
        const options = {
            ...fetchOptions,
            body: data,
            signal: abc.signal
        } as NitroFetchOptions<NitroFetchRequest>;
        configureOptions(url, options);
        return $fetch<ResT>(url, options);
    }

    function configureOptions(key: string, fetchOptions: NitroFetchOptions<NitroFetchRequest>) {
        fetchOptions.onRequest = onRequest;
        const onResponseErrorFunc = fetchOptions.onResponseError;
        fetchOptions.onResponseError = async context => {
            await onResponseError(context);
            await onResponseErrorFunc?.(context);// Hack ...
        };
    }

    function get<ResT>(url: string, fetchOptions: NitroFetchOptions<NitroFetchRequest> = {}) {
        const abc = new AbortController();
        abcs[url] = abc;
        const options = {
            ...fetchOptions,
            signal: abc.signal
        };
        configureOptions(url, options);
        return $fetch<ResT>(url, { ...options, method: "get" });
    }

    function post<ResT, DataType = null>(url: string, data: DataType | null = null,
        fetchOptions: NitroFetchOptions<NitroFetchRequest> = {})
    {
        return doRequest<ResT, DataType>(url, data, { ...fetchOptions, method: "post" });
    }

    function put<ResT, DataType = null>(url: string, data: DataType | null = null,
        fetchOptions: NitroFetchOptions<NitroFetchRequest> = {})
    {
        return doRequest<ResT, DataType>(url, data, { ...fetchOptions, method: "put" });
    }

    function remove<ResT, DataType>(url: string, data?: DataType,
        fetchOptions: NitroFetchOptions<NitroFetchRequest> = {})
    {
        return doRequest<ResT, DataType>(url, data, { ...fetchOptions, method: "delete" });
    }

    async function postWithProgress<ResT, DataT extends FormData = FormData>(url: string, data: DataT,
        uploadProgress: (e: ProgressEvent) => void): Promise<ResT> {

        return new Promise(async (resolve: (r: ResT) => void, reject) => {
            const config = useRuntimeConfig();
            const abc = new AbortController();
            abcs[url] = abc;

            const request = new XMLHttpRequest();
            let baseUrl = config.public.API_URL;
            if (useApiKey) {
                baseUrl = "/remote/proxy/apiKey";
            }
            request.open('POST', `${baseUrl}/${url}`, true);

            //request.setRequestHeader("Content-Type", "multipart/form-data");

            let authToken = await getTokens();
            if (authToken.value?.token) {
                if (isTokenExpired().value) {
                    await refreshTokenAsync();
                    if (!authToken.value) {
                        abcs[url]?.abort("Get away");
                    }
                }
                request.setRequestHeader("Authorization", `Bearer ${authToken.value.token}`);
            }

            request.upload.addEventListener('progress', uploadProgress);

            request.addEventListener('load', function (e) {
                if (request.status >= 200 && this.status < 300) {
                    resolve(JSON.parse(request.response) as ResT);
                } else {
                    reject({
                        status: request.status,
                        statusText: request.statusText
                    });
                }
            });
            request.addEventListener('error', function (e) {
                reject({
                    status: request.status,
                    statusText: request.statusText
                });
            });

            request.send(data);
        });
    }

    return {
        get,
        post,
        put,
        remove,
        postWithProgress
    };
}